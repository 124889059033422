export const paymentService = {
    getSecret,
    charge
}

function getSecret(charge, completion) {
    let options = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(charge),
        method: "POST"
    }
    
    fetch("http://127.0.0.1:3000/secret", options).then(function(response) {
        return response.json()
    }).then(function(json) {
        completion(json.client_secret)
    })
}

function charge(charge) {
    let options = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(charge),
        method: "POST"
    }

    fetch("http://127.0.0.1:3000/charge", options).then(function(response) {
        return response.json()
    }).then(function(json) {
        console.log(json)
    })
}