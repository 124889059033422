const path = require('path')
var fs = require('fs')
var rawData = require('../../invoices.json')

var invoices = []

export const invoicesService = {
    loadInvoices,
    getInvoices,
    getInvoice
}

function loadInvoices() {
    invoices = JSON.parse(JSON.stringify(rawData))

    console.log(invoices)
}

function getInvoices() {
    return invoices
}

function getInvoice(number) {
    var invoice = null

    invoices.forEach(function(i) {
        if (i.number == number) {
            invoice = i
        }
    })

    return invoice
}