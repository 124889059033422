<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Jérémy Peltier Logo"
          class="shrink mr-2"
          contain
          src="https://www.jeremy-peltier.com/wp-content/uploads/2017/07/logo.png"
          transition="scale-transition"
          width="40"
        />
      </div>
    </v-app-bar>
    
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer absolute dark class="justify-center">
      <p style="font-size: small; margin: 0;">2014 - 2020 © Jérémy Peltier - Tous droits réservés - <a href="https://www.jeremy-peltier.com/mentions-legales" class="ml-1" target="_blank" style="color: #9dc66b; text-decoration: none;">Mentions légales</a></p>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>

<style scoped>

</style>