<template>
    <div class="invoice-searcher">
        <v-card>
            <v-card-title>
                Facture
            </v-card-title>
            <v-card-subtitle>
                Rechercher votre facture
            </v-card-subtitle>
            <v-card-text>
                <v-form @submit="onSubmit">
                    <v-text-field label="Numéro de facture" :rules="rules" v-model="number" @keyup="onChange">
                        <div slot="prepend" class="mt-1">F - </div>
                    </v-text-field>
                </v-form>
                <div v-if="number != null && number.length == 5" class="mt-5">
                    <h3>Facture F - {{ number }}</h3>
                    <p v-if="invoice == null">Aucune facture correspondante</p>
                    <p v-if="invoice != null">
                        <span>Client : {{ invoice.customer }}</span><br>
                        <span>Montant : {{ invoice.amount }}€</span>
                    </p>
                </div>
            </v-card-text>
            <v-card-actions v-if="invoice != null">
                <v-spacer></v-spacer>
                <v-btn @click="onPay" dark>
                    Payer
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { invoicesService } from '@/services'

export default {
    name: "invoice-searcher",
    data() {
        return {
            number: null,
            invoice: null,
            rules: [
                value => !!value || 'Ce champ est requis.',
                value => (value && value.length == 5) || 'Le numéro requiert 5 chiffres.'
            ]
        }
    },
    props: ['value'],
    methods: {
        onChange() {
            if (this.number.length != 5) {
                this.invoice = null
                return
            }

            this.invoice = invoicesService.getInvoice(this.number)
        },
        onSubmit() {
            event.preventDefault()
            this.onPay()
        },
        onPay() {
            if (this.invoice == null) {
                return
            }

            this.$emit('input', this.invoice)
        }
    }
}
</script>

<style>
.v-application--is-ltr .v-input__prepend-outer {
    width: 20px !important;
}
</style>