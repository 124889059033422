<template>
    <div class="payment-form">
        <v-card :loading="loading">
            <v-card-title>
                Paiement F - {{ invoice.number }}
            </v-card-title>
            <v-card-text>
                <stripe-elements
                    ref="stripeForm"
                    :pk="publishableKey"
                    :amount="invoice.amout * 100"
                    locale="fr"
                >
                </stripe-elements>
                <span color="danger" v-if="error">{{ error }}</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="submit" dark :loading="loading">Payer {{ invoice.amount }}€</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { StripeElements } from 'vue-stripe-checkout'
import { paymentService } from '@/services'

export default {
    name: 'payment-form',
    props: {
        invoice: Object
    },
    components: {
        StripeElements
    },
    data() {
        return {
            loading: false,
            error: null,
            token: null,
            charge: null,
            publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        }
    },
    methods: {
        submit() {
            this.error = null
            this.loading = true
            this.preparePayment()
        },
        preparePayment() {
            this.charge = {
                amount: this.invoice.amount * 100,
                currency: 'eur',
                number: this.invoice.number,
                email: this.invoice.email,
                description: `Paiement facture F - ${this.invoice.number} par ${this.invoice.customer}`
            }
            this.processPayment(this.charge)
        },
        processPayment(charge) {
            let parent = this
            let stripeForm = this.$refs.stripeForm
            let card = this.$refs.stripeForm.card
            let stripe = this.$refs.stripeForm.stripe

            paymentService.getSecret(charge, function(secret){
                stripe.confirmCardPayment(secret, {
                    payment_method: {
                        card: card
                    }
                }).then(response => {
                    parent.loading = false

                    if (response.error) {
                        parent.error = response.error.message
                    } else {
                        if (response.paymentIntent.status === 'succeeded') {
                            parent.$emit('input', 'succeeed')
                        }
                    }
                })
            })
        }
    }
}
</script>

<style scoped>

</style>