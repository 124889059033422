<template>
	<div class="home">
		<v-container>
			<v-row>
				<v-col cols="12" offset-lg="4" lg="4">
					<invoice-searcher v-model="invoice" v-if="invoice == null"></invoice-searcher>
					<payment-form v-if="invoice != null && payment == null" :invoice="invoice" v-model="payment"></payment-form>
					<payment-successful v-if="payment != null"></payment-successful>
				</v-col>
			</v-row>
		</v-container>
  	</div>
</template>

<script>
import { invoicesService } from '@/services'
import invoiceSearcher from '@/components/InvoiceSearcher'
import paymentForm from '@/components/PaymentForm'
import paymentSuccessful from '@/components/PaymentSuccessful'

export default {
	name: 'Home',
	data() {
		return {
			step: 'invoice-searcher',
			invoice: null,
			payment: null
		}
	},
	mounted() {
		invoicesService.loadInvoices()
	},
  	components: {
	  invoiceSearcher,
	  paymentForm,
	  paymentSuccessful
	},
	methods: {

	}
}
</script>
