<template>
    <div class="payment-successful">
        <v-card>
            <v-card-title>
                Merci !
            </v-card-title>
            <v-card-subtitle>
                Votre paiment a été accepté.
            </v-card-subtitle>
            <v-card-text>
                Merci pour votre paiement et la confiance que vous me portez.<br>
                <br>
                À bientôt.<br>
                <br>
                Jérémy
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'payment-successful'
}
</script>